// category.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SystemService } from './system.service';
import { CallResponse } from '../model/callbackresponse';


@Injectable({
    providedIn: 'root',
})
export class CallResponseService {
    apiUrl: any;
    BearerToken: any;
    callResponse: CallResponse[] = [];
    httpOptions: { headers: any; };



    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    async getAllCallResponse(): Promise<CallResponse[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<CallResponse[]>("Requestcallback");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Requestcallback:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }

    getCallBackById(id: number): Observable<CallResponse> {
        return this.http.get<CallResponse>(`${this.apiUrl}/api/Requestcallback/${id}`);
    }

    async addRequestCallBack(data: CallResponse): Promise<Observable<CallResponse>> {
        console.log(data);
        let res = await this.service.Data.ExecuteAPI_Post("Requestcallback", data);
        console.log(res);
        return res;
    }


    async updateCallBack(callResponse: CallResponse): Promise<Observable<CallResponse>> {
        let res = await this.service.Data.ExecuteAPI_Put("CallResponse",`${callResponse.requestId}?requestId=${callResponse.requestId}`, callResponse);
        return res;
    }

    async deleteCallBack(id: number)
    {
         let res = await this.service.Data.ExecuteAPI_Delete("CallResponse", `${id}?requestId=${id}`);
         return res;
    }
}
