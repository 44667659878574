import { Component, OnInit } from '@angular/core';
import { SystemService } from '../../services/system.service';
import { LogoService } from '../../services/logo.service';
import { ScriptLoaderService } from '../../services/script.service';
@Component({
  selector: 'app-footer-style-two',
  templateUrl: './footer-style-two.component.html',
  styleUrls: ['./footer-style-two.component.scss']
})
export class FooterStyleTwoComponent implements OnInit {
  userID: string = "";
  imageUrl: string;
  apiPath: any;
  logoData: any;
  constructor(private scriptLoader: ScriptLoaderService, public service: SystemService, private LogoService: LogoService) {
    this.apiPath = this.service.Settings.Base_API_URL;
    this.loadlogo();
  }

  ngOnInit(): void {
    
    // this.service.HasAccountData.then((data) => {
    //     this.userID = this.service.Account.userId;
    // }, () => { });
  }
  async loadlogo() {
    await this.LogoService.GetLatestCompanyDetailslatest().then(logoData => {
      this.logoData = logoData;
      console.log(this.logoData, "logoDataGetLatestCompanyDetailslatest");
      this.imageUrl = this.apiPath + "/Documents/appImages/" + this.logoData.logoPath;
      console.log(this.imageUrl, "this.imageUrlheader")
    });

  }

}
