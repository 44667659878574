<footer class="footer-area footer-bg2">
    <div class="footer-middle pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-12">
                    <div class="footer-widget pl-5">
                        <h3>QUICK LINKS</h3>
                        <ul class="footer-list" style="font-size: 18px;">
                            <li><a href="https://alderberrycare.co.uk/" style="color: white;">Live-in Care</a></li>
                            <li><a href="https://alderberrycare.co.uk/cost-of-live-in-care/" style="color: white;">Cost Live-in Care</a></li>
                            <li><a href="https://alderberrycare.co.uk/types-of-care/" style="color: white;">Types of Care</a></li>
                            <li><a href="https://alderberrycare.co.uk/about/" style="color: white;">About</a></li>
                            <li><a href="https://alderberrycare.co.uk/conditions/" style="color: white;">Conditions</a></li>
                            <li><a href="https://alderberrycare.co.uk/contact/" style="color: white;">Contact</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 d-flex justify-content-center">
                    <div class="footer-widget pl-1">
                        <h3>Contact Us</h3>
                        <ul class="footer-list" style="font-size: 18px;">
                            <li><a href="#" style="color: white;">+91 123456789</a></li>
                            <li><a href="#" style="color: white;">info&#64;alderberrycare.co.uk</a></li>
                            <li><a href="#" style="color: white;">UK</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-12 d-flex justify-content-end">
                    <div class="footer-widget">
                        <h3>NEWSLETTER</h3>
                        <p style="color:white;">To get the latest news and updates from us</p>
                        <div class="footer-form">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" class="form-control" required
                                                data-error="Please enter your email" placeholder="Your Email*">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">SUBSCRIBE now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="text-center mt-4">
    <ul class="policy-links list-inline" style="font-size: 16px; ">
        <li class="list-inline-item"><a href="https://alderberrycare.co.uk/privacy-policy/" target="_blank" style="color: black;">Privacy Policy</a></li>
        <li class="list-inline-item">|</li>
        <li class="list-inline-item"><a href="https://alderberrycare.co.uk/terms-conditions/" target="_blank" style="color: black;">Terms and Conditions</a></li>
        <li class="list-inline-item">|</li>
        <li class="list-inline-item"><a href="https://alderberrycare.co.uk/cookie-policy/" target="_blank" style="color: black;">Cookie Policy</a></li>
        <li class="list-inline-item">|</li>
        <li class="list-inline-item"><a href="https://alderberrycare.co.uk/modern-slavery-act/" target="_blank" style="color: black;">Modern Slavery Act</a></li>
    </ul>
</div>

<div class="text-center mt-2">
    <img src="assets/img/Alderberry-Care.jpg" alt="Alderberry Care" style="max-width: 30%; height: auto;">
</div>

<div class="text-center mt-3" style="font-weight: bold;">
    <p style="color: black;">Copyright 2024 Alderberry Care.</p>
</div>