<app-navbar-style-guest></app-navbar-style-guest>

<div fxLayout="column" fxLayoutAlign="stretch stretch" style="min-height: 100vh;">
    <div fxFlex="1 1 auto">
        <div class="container-fluid py-5">
            <div class="row">
                <!-- Banner Section -->
                <div class="row no-gutters min-vh-100">
                    <!-- Banner Section -->
                     
                    <div class="col-lg-6 inner-banner inner-bg3 d-flex flex-column align-items-center justify-content-center">
                        <div class="inner-banner-title text-center px-3">
                            <h3 class="mt-3">Request Call Back</h3>
                            <p class="mt-3">Alderberry Care is here to support you. If you're looking for compassionate and professional care services, request a call back by filling out the form. We offer personalized assistance for all your care needs.</p>
                            <br/>
                            <p class="mt-3"><span style="background-color: yellow; border-radius: 5px; color:black;">What We Offer:</span></p>
                            <p class="mt-3">We provide dedicated caregivers ready to assist with personal care, daily activities, and more. Let us help you find the right care solution for your needs.</p>
                            <br/>
                            <p class="mt-3">Thank you for trusting Alderberry Care. We look forward to providing you with the best support.</p>
                        </div>
                    </div>
                    
                
                    <!-- Form Section -->
                    <div class="col-lg-6">
                        <div class="listing-widget-section pt-25 pb-70">
                            <!-- <div class="container">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 d-flex flex-column align-items-center">
                                        <div class="agree-label mb-3" *ngIf="!isLoggedIn">
                                            <label style="color: red; font-size: 20px;">Please Login to fill the form!</label>
                                        </div>
                                        <button *ngIf="!isLoggedIn" type="button" (click)="login()" class="default-btn border-radius">Login<i class='bx bx-plus'></i></button>
                                    </div>
                                </div>
                            </div> -->
                            
                            <div class="container pt-50">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card" >
                                            <header class="card-header" style="background: var(--blue);border-radius:100px;">
                                                <h4 class="card-title mt-2 text-center" style="color:white">Request Call Back</h4>
                                            </header>
                                            <div class="card-body">
                                                <form [formGroup]="EmbassyForm" (ngSubmit)="onSubmit()">
                                                    <div class="form-group">
                                                        <label for="yourName">Full Name:</label>
                                                        <input type="text" class="form-control" formControlName="yourName" required>
                                                        <div *ngIf="EmbassyForm.get('yourName')?.hasError('required') && EmbassyForm.get('yourName')?.touched" class="text-danger">
                                                            Name is required.
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="email">Email-ID:</label>
                                                        <input type="text" class="form-control" formControlName="email" required>
                                                        <div *ngIf="EmbassyForm.get('email')?.hasError('required') && EmbassyForm.get('email')?.touched" class="text-danger">
                                                            Email-Id is required.
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="phoneNo">Phone No:</label>
                                                        <div class="input-group">
                                                           
                                                            <input type="text" class="form-control" formControlName="phoneNo" required>
                                                        </div>
                                                       
                                                        <div *ngIf="EmbassyForm.get('phoneNo')?.hasError('required') && EmbassyForm.get('phoneNo')?.touched" class="text-danger">
                                                           Phone No is required.
                                                        </div>
                                                        <div *ngIf="EmbassyForm.get('phoneNo')?.hasError('invalidPhoneNumber') && EmbassyForm.get('phoneNo')?.touched" class="text-danger">
                                                            Please enter a valid phone number.
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                   
                                                        <div class="form-group">
                                                            <i class="flaticon-calendar"></i>
                                                            <input type="text" formControlName="dateTime" required class="form-control"
                                                                placeholder="Date & Time">
                                                            <ng-container *ngIf="EmbassyForm && EmbassyForm.get('dateTime')">
                                                                <div style="color: red;"
                                                                    *ngIf="EmbassyForm.get('dateTime').hasError('required') && EmbassyForm.get('dateTime').touched">
                                                                    DateTime is required.
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                 
                                                    
                                                    <div class="form-group">
                                                        <label for="yourMessage">Message:</label>
                                                        <textarea class="form-control" formControlName="yourMessage" required></textarea>
                                                        <div *ngIf="EmbassyForm.get('yourMessage')?.hasError('required') && EmbassyForm.get('yourMessage')?.touched" class="text-danger">
                                                            This field is required.
                                                        </div>
                                                    </div>
                                                  
                                                
                                                    
                                                    
                                                  
                                                    <div class="form-group text-center">
                                                        <button type="submit" style="font-size: 15px;" class="default-btn border-radius w-100">Save</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><div *ngIf="showPopup" class="popup-overlay">
        <div class="popup-content">
          <h2>Thank You!</h2>
          <p style="color: black;">Successfully Submitted,<span style="color: orangered;"> Thank You For your Response We Will get back to you soon </span>and click OK to return home page.</p>
          <button (click)="closePopup()">OK</button>
        </div>
      </div>
      

    <app-footer-style-two></app-footer-style-two>
</div>
