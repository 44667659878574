import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { embassyFormModel } from '../../model/embassy_form';
import { SystemService } from '../../services/system.service';
import { AlertifyService } from '../../services/alertify.service';

import { CallResponseService } from '../../services/callresponse';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { CallResponse } from '../../model/callbackresponse';

@Component({
  selector: 'app-embassy_form',
  templateUrl: './embassy_form.html',
  styleUrls: ['./embassy_form.scss']
})
export class EmbassyFormComponent implements OnInit {
    EmbassyForm: FormGroup;
    currentUser = localStorage.getItem('token');
    isLoggedIn = !!localStorage.getItem("id");
    typelist: any[] = [];
    selectedtype: embassyFormModel | null = null;
    date: any = new Date().toISOString();
    returnUrl: string = '';
    showPopup: boolean = false;
    constructor(
        private fb: FormBuilder,
        private router: Router,
        public requestservice: CallResponseService,
        public service: SystemService,
        private alertify: AlertifyService
    ) {}

    selectedDocumentation: string[] = [];

    ngOnInit() {
        this.initializeForm();
        // if (!this.isLoggedIn) {
        //     this.returnUrl = this.router.url;
        // }
    }

    initializeForm() {
        this.EmbassyForm = this.fb.group({
          
            yourName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phoneNo: [''],
            dateTime: [''],
            yourMessage: ['', Validators.required],
        });
    }

    phoneNumberValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const value = control.value;
        const isValid = /^\d{9}$/.test(value);
        return isValid ? null : { 'invalidPhoneNumber': true };
    }

    async onSubmit() {
        if (this.EmbassyForm.valid) {
            let obj = this.EmbassyForm.getRawValue();
            console.log(JSON.stringify(obj));
            let res = this.requestservice.addRequestCallBack(obj);
            if (res) {
                this.showPopup=true;
            }
        }
        this.EmbassyForm.reset();
    }
    closePopup(): void {
        this.showPopup = false;
        this.router.navigate(['/listing']);
      }
    // login() {
    //     this.router.navigate(['/login-register'], { queryParams: { returnUrl: this.returnUrl } });
    // }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }

    blured = false
    focused = false

    created(event) {
        console.log('editor-created', event);
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        console.log('editor-change', event);
    }

    focus($event) {
        console.log('focus', $event);
        this.focused = true;
        this.blured = false;
    }

    blur($event) {
        console.log('blur', $event);
        this.focused = false;
        this.blured = true;
    }
}
