import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TypeService } from '../../services/type.services';
import { SubCategory } from '../../model/subcategory';
import { Category } from '../../model/category';
import { CategoryService } from '../../services/category.service';
import { PostsService } from '../../services/posts.service';
import { SubCategoryService } from '../../services/subcategory.service';
import { SystemService } from '../../services/system.service';
import { ImageGalleryService } from '../../services/imagegallery.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home-guest',
    templateUrl: './home-guest.component.html',
    styleUrls: ['./home-guest.component.scss']
})
export class HomeGuestComponent implements OnInit {
    typeCardlist: any;
    isLoading: boolean = true;
    bannerPhoto: any; slickModal: any;
    distance = 0;
    cards: string[];// = ['#00e676', '#e91e63', '#2196f3', '#009688', '#f44336']
    videoUrl = 'https://www.youtube.com/watch?v=xAtMVS5WTr4';
    @ViewChild('myIframe') myIframe: ElementRef;
    searchTerm: any;
    isUser: boolean;
    posts: any[]; filteredTypeCardlistposts: any;
    categories: Category[];
    subcategories: SubCategory[];
    https: any;
    videoHeight: number | undefined;
    videoWidth: number | undefined;
    isLoadingMainPage: boolean = true;
    @ViewChild('youtubePlayerContainer', { static: false }) youtubePlayerContainer: ElementRef;
    apiPath: any;
    galleryItems: any;
    rowData: any;
    currentIndex = 0;
    interval: any;
    userName = localStorage.getItem('username');
    userEmail = localStorage.getItem('email');
goto(){
    this.router.navigate(['/listing']);
}
    constructor( private router:Router, private sanitizer: DomSanitizer,public service: SystemService,private changeDetectorRef: ChangeDetectorRef,private typeService: TypeService, private postsService: PostsService, private SubcategoryService: SubCategoryService,
        private categoryService: CategoryService, private renderer: Renderer2, private el: ElementRef,private imageGalleryService: ImageGalleryService,) { this.loadCategories(); }

    ngOnInit(): void {
        this.apiPath = this.service.Settings.Base_API_URL;
        this.loadGallery();
        this.loadtypecardlist();
        this.bindpost();
        this.isLoadingMainPage =true;
        const screenWidth: number = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        console.log(screenWidth);
    }

    sanitizeImage(binaryData: string) {
        const imageUrl = binaryData; // adjust MIME type accordingly
        return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
      }


    async loadGallery() {
        this.service.App.ShowLoader = true;
        this.imageGalleryService.getAllGalleryshomebanner().then(gallerylist => {
            console.log(gallerylist,"gallerylist")
            this.galleryItems = gallerylist;
            this.rowData = gallerylist;
            console.log(JSON.stringify(this.galleryItems));
        });
        this.service.App.ShowLoader = false;

    }
    isReady  = false;

    onReadyChange($event: boolean) {
        this.isReady  = $event;
    }

    private updateContainerStyles() {
        if (this.youtubePlayerContainer) {
          const containerElement = this.youtubePlayerContainer.nativeElement;
            containerElement.style.height = '1024px';
            // Add other styles as needed
          }
      }

    ngAfterViewInit() {
        this.updateContainerStyles();
        // Stop loading after 5 seconds
        setTimeout(() => {
          this.isLoadingMainPage =false;
        }, 1000);
      }


      async loadtypecardlist() {
        // this.isLoading = true;
        // console.log(this.isLoading);
        // await this.typeService.getAllTypecardlistHome().then(typeCardlist => {
        //     this.typeCardlist = typeCardlist;
        //     console.log(JSON.stringify(this.typeCardlist));
        // });
        // this.isLoading = false;
        // this.isLoading = true;
        // const allTypeCitieslist = await this.typeService.geAllTypeCitieslistHomeCall();
        // this.typeCardlist = this.shuffleArray(allTypeCitieslist).slice(0, 6);
        // console.log(this.typeCardlist);
        // const colorCodes = this.typeCardlist.map(obj => '#' + obj.typeId.toString(16).padStart(6, '0'));
        // this.cards = colorCodes;
        // console.log(this.cards);
        // this.isLoading = false;


        this.isLoading = true;
        console.log(this.isLoading);
        this.typeCardlist = await this.typeService.geAllTypeCitieslistHomeCall();
        console.log(JSON.stringify(this.typeCardlist));
        console.log(this.typeCardlist,"this.typeCardlist");
        try {
            this.typeCardlist.forEach(card => {
                card.categoryNames = [];

                // Split the comma-separated string into an array of category IDs
                const cardCategoryIds = card.categoryId.split(',');
                console.log(cardCategoryIds);
                // Find all matching categories for each category ID in the array
                cardCategoryIds.forEach(cardCategoryId => {
                    console.log(cardCategoryId);
                    console.log(JSON.stringify(this.categories));
                    if (Number(cardCategoryId)) {
                        const matchedCategory = this.categories.find(category => category.categoryId === Number(cardCategoryId));
                        console.log(matchedCategory);
                        if (matchedCategory) {
                            // Add the matching category name to the array
                            //card.categoryNames.push(matchedCategory.categoryName);
                            card.categoryNames += ` ${matchedCategory.categoryName}`;
                        } else {
                            console.warn(`No matching category found for categoryId: ${cardCategoryId}`);
                            // You can choose to set a default category name or handle this case differently
                        }
                    }
                });
            });
        }
        catch
        {
            console.warn(`Error`);
        }
        console.log(this.typeCardlist);
        this.typeCardlist = this.shuffleArray(this.typeCardlist);
        this.isLoading = false;
    }


    shuffleArray(array: any[]): any[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }


    async bindpost() {
        this.isLoading = true;
        console.log(this.isLoading);
        this.posts = await this.postsService.geAllPostHomeCall();
        console.log(JSON.stringify(this.posts));
        this.filteredTypeCardlistposts = this.posts;
        this.isLoading = false;
    }

    //******************************************SEARCH********************************************/
    onSearchTermChange(): void {
        // Update the filtered list based on the search term
        console.log(JSON.stringify(this.posts));
        this.filteredTypeCardlistposts = this.posts.filter(obj =>
            obj.title.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
    }
    //******************************************SEARCH********************************************/


    loadCategories() {
        this.categoryService.getAllCategories().then(categories => {
            this.categories = categories;
            console.log(JSON.stringify(this.categories));
            // Example usage
            const searchIds = [40, 42]; // Replace with the IDs you want to search
            //const resultCategoryNames = getCategoryNamesById(searchIds);
            //console.log(resultCategoryNames);

        });
    }

    getCategoryNames(category: string): string {
        // Convert category string to an array of category names
        const categoryIds = category.split(',').map(Number);
        const categoryNames = this.categories
            .filter(item => categoryIds.includes(item.categoryId))
            .map(item => item.categoryName);

        return categoryNames.join(', ');
    }
}
