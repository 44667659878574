<app-navbar-style-guest></app-navbar-style-guest>
<br>

<div class="listing-widget-section pt-25 pb-70">
    <div class="container">
        
            

                <div class="listing-widget-into">
                    <div class="row">
                        <div class="col-lg-9 col-sm-9">
                            <div class="listing-right-form">
                                <div class="row">
                                    <!-- Sort by and search input field (existing structure) -->
                                    <div class="col-lg-7 col-sm-8">
                                        <div class="form-group">
                                            <label>Sort by:</label>
                                        </div>
                                        <div class="form-group d-flex align-items-center">
                                            <i class="flaticon-loupe" style="color: rgb(83, 116, 216); margin-right: 8px;"></i>
                                            <input type="text" class="form-control" placeholder="What Are You Searching*" 
                                                [(ngModel)]="searchTerm" (input)="onSearchTermChange()">
                                        </div>
                                    </div>
                        
                                    <!-- Select Category label and dropdown with same structure as Sort by -->
                                    <div class="col-lg-5 col-sm-4">
                                        <div class="form-group">
                                            <label>Select Category:</label>
                                        </div>
                                        <div class="custom-form">
                                            <div class="input-container">
                                                <i class='flaticon-list' style="color: var(--blue);"></i>
                                                <ng-select #myselectedCategoryId class="form-control" [multiple]="true"
                                                           (change)="onCategoryChange($event)" [(ngModel)]="selectedCategoryId" 
                                                           [ngModelOptions]="{standalone: true}">
                                                    <ng-option *ngFor="let obj of categotyList"
                                                               [value]="obj.categoryId">{{obj.categoryName}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        <!-- <div class="col-lg-3 col-sm-3">
                            <ul class="listing-widget-menu">
                                <li><a routerLink="/listing" class="active"><i class="flaticon-filter"></i></a></li>
                                <li><a routerLink="/listing"><i class="flaticon-menu"></i></a></li>
                            </ul>
                        </div> -->

                    </div>

                    <div class="row">
                        <div *ngIf="isLoadingGrid">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title text-center">
                                        <span style="color: rgb(83, 116, 216);">Please wait, We're loading some exciting content for you</span>
                                    </div>
                                    <div class="spinner" style="background-color: rgb(83, 116, 216);"></div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf=" !isLoadingGrid && (!filteredTypeCardlist || filteredTypeCardlist.length === 0)">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title text-center">
                                        <span style="color:rgb(83, 116, 216) ;">No Data Available</span>
                                    </div>
                                    <div class="container">
                                        <div class="row justify-content-center">
                                            <div class="col-12 col-md-8 col-lg-6 col-xl-4 text-center">
                                                <img src="../../../../assets/img/no-data.jpg" alt="No Data" class="img-fluid" style="max-width: 100%; opacity: 0.3;"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>

                        
                        <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-stretch mb-4"  *ngFor="let obj of filteredTypeCardlist">
                            <div class="place-list-item">
                                <div class="images">
                                    <a [routerLink]="['/listing-details/', obj.typeId]">
                                        <img [src]="this.apiPath + 'Documents/appImages/' + obj.bannerPhoto"

                                            alt="Images" width="400" height="300">

                                    </a>

                                    <!-- <div class="place-profile">
                                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                                        <h3>By, Alfred</h3>
                                    </div> -->

                                    <div class="place-status bg-dark-orange">
                                        <!-- <a [routerLink]="['/listing-details/', obj.typeId]"> -->
                                        <a [routerLink]="['/listing-details/', obj.typeId]">
                                            <!-- <h3>{{obj.openClose}}</h3> -->
                                             <h3>Apply Now</h3>
                                        </a>
                                    </div>

                                    <div class="place-tag">
                                        <!-- <ul>
                                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                                        </ul> -->
                                        <span><a href="#"></a></span>
                                        <h3 class="title"><a
                                                [routerLink]="['/listing-details/', obj.typeId]">{{obj.categoryNames}}</a>
                                        </h3>
                                    </div>
                                </div>

                                <div class="content">
                                    <a [routerLink]="['/listing-details/', obj.typeId]">
                                        <h3>{{ obj.typeTitle }}</h3>
                                    </a>

                                    <p><i class="flaticon-cursor" style="color:  rgb(83, 116, 216);"></i>{{obj.location}} </p>
                                    <!-- <ul class="place-rating">
                                        <li><a [routerLink]="['/listing-details/', obj.typeId]">{{obj.rating}}</a></li>
                                       
                                        <li *ngFor="let star of [1, 2, 3, 4, 5]; let i = index">
                                            <i class="bx" [ngClass]="{'bxs-star': i < 5, 'icon-color': i >= 5}"></i>
                                        </li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-12 text-center">
                            <a routerLink="/listing" class="default-btn border-radius">Load More <i
                                    class='bx bx-plus'></i></a>
                        </div> -->



                        <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-stretch mb-4">
                            <div class="place-list-item">
                                <div class="images">
                                    <a href="form">
                                        <img src="assets/img/callback.jpg"

                                            alt="Images" width="400" height="300">

                                    </a>

                                    <!-- <div class="place-profile">
                                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                                        <h3>By, Alfred</h3>
                                    </div> -->

                                    <div class="place-status bg-dark-orange">
                                        <!-- <a [routerLink]="['/listing-details/', obj.typeId]"> -->
                                        
                                    </div>

                                    <div class="place-tag">
                                        <!-- <ul>
                                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                                        </ul> -->
                                        <span><a href="#"></a></span>
                                        <h3 class="title"><a
                                                href="form">Book a call</a>
                                        </h3>
                                    </div>
                                </div>

                                <div class="content">
                                    <a href="form">
                                        <h3>Request A Call</h3>
                                    </a>

                                    <p><i class="flaticon-cursor" style="color:  rgb(83, 116, 216);"></i>UK </p>
                                    <!-- <ul class="place-rating">
                                        <li><a [routerLink]="['/listing-details/', obj.typeId]">{{obj.rating}}</a></li>
                                       
                                        <li *ngFor="let star of [1, 2, 3, 4, 5]; let i = index">
                                            <i class="bx" [ngClass]="{'bxs-star': i < 5, 'icon-color': i >= 5}"></i>
                                        </li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           
       
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
