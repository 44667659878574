<div class="navbar-area">
    <div class="mobile-nav">
        <!-- <a routerLink="/" class="logo"><img  [src]="imageUrl" alt="Logo" width="100px"></a> -->
        <a routerLink="/listing" class="logo"><img  src="assets/img/navimage.png" alt="Logo" width="100px"></a>
    </div>




    <div class="nav-two main-nav">
        <div class="container-fluid">
            <nav class="container-max navbar navbar-expand-md navbar-light">
           
                <a class="navbar-brand" routerLink="/listing"><img src="assets/img/Alderberry-Care.jpg" alt="Logo"
                        width="200px"></a>


                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                     <!-- <li class="nav-item" *ngFor="let menuItem of MenuDetailsDisp">
                            <a *ngIf="menuItem.menuUrl; else defaultLink" routerLink="/{{menuItem.menuUrl}}" class="nav-link">{{ menuItem.menuName }}<i class='bx bx-plus'></i></a>
                            <ng-template #defaultLink>
                                 <a routerLink="/pagecontent/{{ menuItem.menuId }}" class="nav-link">{{ menuItem.menuName }} <i class='bx bx-plus'></i></a>
                            </ng-template>
                            <ul class="dropdown-menu">
                                <ng-container *ngFor="let submenuItem of menuItem.subMenus">
                                    <li class="nav-item" *ngIf="menuItem.subMenus && menuItem.subMenus.length > 0">
                                        <a *ngIf="submenuItem.menuUrl; else defaultLink" routerLink="/{{submenuItem.menuUrl}}" class="nav-link">{{ submenuItem.menuName }}</a>
                                        <ng-template #defaultLink>
                                            <a routerLink="/pagecontent/{{ submenuItem.menuId }}" class="nav-link">{{submenuItem.menuName }}</a>
                                        </ng-template>
                                        <ul class="dropdown-menu" *ngIf="submenuItem.subMenus && submenuItem.subMenus.length > 0"> 
                                            <li class="nav-item">
                                                <ng-container *ngFor="let subSubmenu of submenuItem.subMenus">
                                                    <li><a *ngIf="subSubmenu.menuUrl; else defaultLink" routerLink="/{{subSubmenu.menuUrl}}" class="nav-link">{{ subSubmenu.menuName }}</a>
                                                    <ng-template #defaultLink>
                                                        <a routerLink="/pagecontent/{{ subSubmenu.menuId }}" class="nav-link">{{subSubmenu.menuName }}</a>
                                                    </ng-template></li>
                                                    \
                                                    <ul class="dropdown-menu custom-dropdown" *ngIf="subSubmenu.subMenus && subSubmenu.subMenus.length > 0">
                                                        <ng-container *ngFor="let subSubSUbmenuItem of subSubmenu.subMenus">
                                                            <li><a *ngIf="subSubSUbmenuItem.menuUrl; else defaultLink" routerLink="/{{subSubSUbmenuItem.menuUrl}}" class="nav-link">{{ subSubSUbmenuItem.menuName }}</a>
                                                                <ng-template #defaultLink>
                                                                    <a routerLink="/pagecontent/{{ subSubSUbmenuItem.menuId }}" class="nav-link">{{subSubSUbmenuItem.menuName }}</a>
                                                                </ng-template></li>
                                                           
                                                        </ng-container>
                                                    </ul>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ul>
                        </li> -->
                    </ul>




                    <div class="side-nav d-in-line align-items-center">
                        <div class="side-item">
                            <div class="cart-btn">
                                <i class="flaticon-contact " style="color: rgb(83, 116, 216);" *ngIf="userName"></i>

                            </div>
                        </div>
                        <div class="side-item">
                            <div class="search-box">
                                <span *ngIf="userName">Welcome, {{userName}}</span>
                            </div>
                        </div>

                        <div class="side-item">
                            <div class="nav-add-btn">

                                <a routerLink="/login-register" *ngIf="!userName" class="default-btn">Log
                                    in<i class='bx bx-plus'></i></a>
                                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent"
                                    *ngIf="userName">
                                    <ul class="navbar-nav m-auto">
                                        <li class="nav-item">
                                            <button class="default-btn" *ngIf="userName" (click)="logout()">Log Out<i
                                                    class='bx bx-plus'></i></button>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item"><a routerLink="/profiles" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Profile</a></li>
                                                <!-- <li class="nav-item"><a routerLink="/pricing" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Pricing</a></li> -->
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>











    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center" >
                        <div class="side-item">
                            <div class="cart-btn">
                                <i class="flaticon-contact"></i> <span>{{userName}}</span>
                            </div>
                        </div>


                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/login-register" *ngIf="!userID" class="default-btn border-radius">Log
                                    in<i class='bx bx-plus'></i></a>
                                <a href="https://alderberrycare.co.uk" class="default-btn" *ngIf="userID">Log Out<i
                                        class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>
            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
